<template>
  <el-row>
    <el-col :span="3">
      <right-menu @ok="changeGroup" @groupName="setName" />
    </el-col>

    <el-col :span="21">
      <div class="app-container">
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          v-show="showSearch"
          label-width="68px"
        >
          <el-form-item label="客户名称" prop="customerName">
            <el-input
              v-model="queryParams.customerName"
              placeholder="请输入客户名称"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
              type="info"
              plain
              icon="el-icon-upload"
              size="mini"
              @click="openImport"
              v-hasPermi="['custom:index:importResult']"
              >导入结果</el-button
            >
          </el-col>

          <el-col :span="1.5">
            <el-button
              type="info"
              plain
              icon="el-icon-refresh-left"
              size="mini"
              :disabled="multiple"
              @click="handlePut"
              v-hasPermi="['custom:index:put']"
              >分配资源</el-button
            >
          </el-col>
          <el-col :span="1.5">
            <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              size="mini"
              :disabled="multiple"
              @click="handleDelete"
              v-hasPermi="['custom:index:remove']"
              >删除资源</el-button
            >
          </el-col>
        </el-row>

        <el-table
          v-loading="loading"
          :data="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
          ></el-table-column>
          <el-table-column label="id" align="center" prop="id" width="60" />
          <el-table-column
            label="公司名称"
            align="center"
            prop="customerName"
          />

          <el-table-column label="地区" align="center" prop="contactAddress" />
          <el-table-column label="联系人" align="center" prop="linkMan" />
          <el-table-column label="联系人电话" align="center" prop="mobile" />
          <el-table-column
            label="备用手机号"
            align="center"
            prop="alternatePhone"
          />
          <el-table-column
            label="资源分组"
            align="left"
            prop="grouping"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ groupName[scope.row.grouping] }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作人" align="center" prop="operator" />

          <el-table-column label="所有者" align="center" prop="owner" />
          <el-table-column label="创建时间" align="center" prop="createTime">
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="handleEditTable(scope.row)"
                v-hasPermi="['custom:index:edit']"
                >修改分组</el-button
              >
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                v-hasPermi="['custom:index:remove']"
                >删除</el-button
              >
              <el-button
                type="text"
                size="small"
                icon="el-icon-folder"
                @click="openRecordTable(scope.row)"
                v-hasPermi="['custom:index:record']"
                >记录</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />

        <record-table ref="record" :id="recordId" />
        <form-dialog ref="form" @ok="getList" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {
  exportData,
  delData,
  editingGroup,
  listData,
  downLoadData,
} from "@/api/custom/origin";
import { backData } from "@/api/custom/index";

import recordTable from "./components/recordTable.vue";
import formDialog from "./components/formDialog.vue";
import rightMenu from "./components/menu.vue";

export default {
  name: "Gen",
  components: { recordTable, formDialog, rightMenu },
  data() {
    return {
      // 左边默认打开
      openeds: ["all"],
      // 遮罩层
      loading: true,
      // 唯一标识符
      uniqueId: "",
      // 选中数组
      ids: [],
      // 选中表数组
      tableNames: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表数据
      tableList: [],
      // 日期范围
      dateRange: "",
      // 客户类型
      customerOptions: [],
      // 进度
      processOptions: [],

      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dataStatus: 1,
        grouping: undefined,
        operator: undefined,
        salesProcess: undefined,
        customerName: undefined,
      },
      // 记录弹框id
      recordId: "",
      groupName: {},
    };
  },
  created() {
    this.getList();
  },

  methods: {
    /** 分组查询 */
    changeGroup(grouping) {
      if (grouping) {
        this.queryParams.grouping = grouping;
      } else {
        this.queryParams.grouping = null;
      }
      this.getList();
    },
    // 显示分组名称
    setName(dic) {
      this.groupName = dic;
    },
    /** 查询表集合 */
    getList() {
      this.loading = true;

      listData(this.queryParams).then((response) => {
        this.tableList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 添加
    handleAdd() {},

    /** 下载模板 */
    handleGenTable() {
      downLoadData().then((res) => {
        const aLink = document.createElement("a");
        aLink.href = config.baseUrl + res.msg;
        document.body.appendChild(aLink);
        aLink.setAttribute("target", "_blank");
        aLink.click();
        document.body.removeChild(aLink);
      });
    },
    // 导入结果
    openImport() {
      this.$refs.import.show();
    },
    // 分配资源
    handlePut() {
      const ids = this.ids.join(",");
      this.$confirm('是否确认分配表id为"' + ids + '"的资源数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return backData({ ids: this.ids });
        })
        .then(() => {
          this.getList();
          this.msgSuccess("分配成功");
        });
    },
    /** 打开记录表弹窗 */
    openRecordTable(row) {
      this.recordId = row.id;
      this.$refs.record.show();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.tableNames = selection.map((item) => item.tableName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 修改按钮操作 */
    handleEditTable(row) {
      this.$refs.form.show(row);
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const tableIds = row.id || this.ids.join(",");

      this.$confirm('是否确认删除表id为"' + tableIds + '"的资源数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delData(tableIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm("是否确认导出所有资源?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.exportLoading = true;
          return exportData(queryParams);
        })
        .then((response) => {
          this.download(response.msg);
          this.exportLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.el-menu {
  height: calc(100vh - 90px) !important;
}
</style>
