/*
 * @Author: your name
 * @Date: 2021-07-07 09:29:58
 * @LastEditTime: 2021-07-07 11:57:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/api/business/workNumber.js
 */
import request from "@/utils/request";

// 用户工作手机号列表
export function getCallUserPhoneList(query) {
    return request({
        url: "/business/isCurrentPhone/getCallUserPhoneByUserId",
        method: "get",
        params: query,
    });
}
export function changeCallLine(data) {
    return request({
        url: "/business/isCurrentPhone/changeCallLine",
        method: "post",
        data: data,
    });
}

// 新增用户工作手机号
export function insertCallPhone(data) {
    return request({
        url: "/business/isCurrentPhone/insertCallPhone2",
        method: "post",
        data: data,
    });
}

// 修改用户工作手机号
export function updateCallPhone(data) {
    return request({
        url: "/business/isCurrentPhone/changeCallLinePhone",
        method: "post",
        data: data,
    });
}

// 删除用户工作手机号
export function delCallPhone(data) {
    return request({
        url: "/business/isCurrentPhone/remove",
        method: "delete",
        data: data
    });
}
// 获取呼叫方式列表
export function getCallOutType(data) {
    return request({
        url: "business/isCurrentPhone/getCallOutType",
        method: "get",
    });
}

// 查询自己的默认呼叫模式
export function getUserCallOutType(data) {
    return request({
        url: "business/isCurrentPhone/getUserCallOutType",
        method: "get",
    });
}

// 修改默认呼叫模式
export function updateCallOutType(data) {
    return request({
        url: "business/isCurrentPhone/updateCallOutType",
        method: "post",
        data
    });
}
