/*
 * @Author: your name
 * @Date: 2021-06-02 10:55:38
 * @LastEditTime: 2021-07-18 21:52:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /call_manage_system_vue/src/store/modules/user.js
 */
import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";

const user = {
    state: {
        token: getToken(),
        name: "",
        avatar: "",
        user_id: "",
        phone_number: "",
        roles: [],
        permissions: [],
        userType: "",
        companyID: "",
        companyName: "",
        workPhone: "",
        deptId: "",
        callModel: ""
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_USER_ID: (state, user_id) => {
            state.user_id = user_id;
        },
        SET_PHONE_NUMBER: (state, phonenumber) => {
            state.workPhone = phonenumber;

        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
        SET_DEPTID: (state, deptId) => {
            state.deptId = deptId;
        },
        SET_USERTYPE: (state, userType) => {
            state.userType = userType;
        },
        SET_COMPANYID: (state, companyID) => {
            state.companyID = companyID;
        },
        SET_COMPANYNAME: (state, companyName) => {
            state.companyName = companyName;
        },
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            const code = userInfo.code;
            const uuid = userInfo.uuid;
            return new Promise((resolve, reject) => {
                login(username, password, code, uuid)
                    .then((res) => {
                        setToken(res.token);
                        commit("SET_TOKEN", res.token);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo()
                    .then((res) => {
                        const user = res.user;
                        const avatar =
                            user.avatar == ""
                                ? require("@/assets/images/profile2.png")
                                : process.env.VUE_APP_BASE_API + user.avatar;

                        //  开发】：dev  【测试】：sit  【预发布】：stag  【生产】：pro
                        let sysEnv = "sit";
                        console.log("111", process.env.NODE_ENV);
                        if (process.env.NODE_ENV == "production") {
                            sysEnv = "pro";
                        } else {
                            sysEnv = "sit";
                        }
                        console.log(sysEnv, "sysEnv");
                        if (res.roles && res.roles.length > 0) {
                            // 验证返回的roles是否是一个非空数组
                            commit("SET_ROLES", res.roles);
                            commit("SET_PERMISSIONS", res.permissions);
                        } else {
                            commit("SET_ROLES", ["ROLE_DEFAULT"]);
                        }

                        commit("SET_DEPTID", user.deptId);
                        commit("SET_NAME", user.nickName);
                        commit("SET_AVATAR", avatar);
                        commit("SET_USER_ID", user.userId);
                        commit("SET_USERTYPE", user.userType);
                        commit("SET_COMPANYID", user.deptId);
                        commit("SET_COMPANYNAME", user.companyName);
                        commit("SET_PHONE_NUMBER", user.phonenumber);
                        console.log(process.env, "process.env");
                        window.localStorage.wmUserInfo = JSON.stringify({
                            userId: user.userId,
                            userTag: "tag",
                            projectVersion: "1.0.1",
                            env: sysEnv,
                        });
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_TOKEN", "");
                        commit("SET_ROLES", []);
                        commit("SET_PERMISSIONS", []);
                        removeToken();
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise((resolve) => {
                commit("SET_TOKEN", "");
                removeToken();
                resolve();
            });
        },
    },
};

export default user;
