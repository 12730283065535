<template>
  <div>
    <!-- <el-button
      plain
      icon="el-icon-plus"
      size="mini"
      style="margin: 20px 0 10px 10px"
      @click="handleAdd"
      v-hasPermi="['custom:index:addCategory']"
      >新建分类</el-button
    > -->

    <el-tree
      :data="list"
      node-key="id"
      default-expand-all
      @node-click="handleselect"
      :expand-on-click-node="false"
    >
      <!-- <span class="custom-tree-node"   :class="{ active: data.id === selectId || (selectId === '' && node.level === 1) }" slot-scope="{ node, data }">
        <span>{{  node.label }}</span>
        <span v-show="node.level > 1">
          <el-button type="text" size="mini" @click="() => handleUpdate(data)">
            修改
          </el-button>
          <el-button type="text" size="mini" @click="() => handleDelete(data)">
            删除
          </el-button>
        </span>
      </span> -->
    </el-tree>

    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="选择所属分组" prop="parentId">
          <el-cascader
            :disabled="!!form.id"
            :props="{ checkStrictly: true }"
            v-model="form.parentId"
            :options="options"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listGroup, delGroup, updateGroup, addGroup } from "@/api/custom/group";
export default {
  name: "rightMenu",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [], // 分组多级
      list: [], // 分组树
      allId: "", // 所有资源id
      selectId: "", // 当前选中id

      // 弹框
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        parentId: [
          { required: true, message: "请选择所属分组", trigger: "change" },
        ],
      },
      form: {
        parentId: [],
        remark: "",
        name: "",
      },
      open: false,
      title: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 菜单筛选
    handleselect(row) {
      if (row.id == this.allId) {
        this.selectId = "";
        this.$emit("ok");
      } else if (!row.children?.length) {
        this.selectId = row.id;
        this.$emit("ok", row.id);
      }
    },
    // 查询表数据
    getList() {
      listGroup().then((res) => {
        if (res.code === 200) {
          this.list = [
            {
              id: res.data.id,
              label: "所有资源",
              level: 1,
              children:
                res.data.groupList?.map((item) => ({
                  ...item,
                  id: item.id,
                  label: item.name,
                  level: 2,
                  children:
                    item.groupList?.map((child) => ({
                      ...child,
                      id: child.id,
                      label: child.name,
                      level: 3,
                    })) ?? [],
                })) ?? [],
            },
          ];
          this.allId = res.data.id + "";
          this.openeds = [this.allId];

          this.options = [
            {
              value: res.data.id,
              label: "所有资源",
              level: 1,
              children:
                res.data.groupList?.map((item) => ({
                  value: item.id,
                  label: item.name,
                  level: 2,
                })) ?? [],
            },
          ];

          const groupName = {};
          res.data.groupList?.forEach((item) => {
            groupName[item.id] = item.name;
            item.groupList?.forEach((child) => {
              groupName[child.id] = child.name;
            });
          });
          this.$emit("groupName", groupName);
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const tableIds = row.id;
      this.$confirm('是否确认删除"' + row.name + '"?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delGroup(tableIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        });
    },
    // 添加
    handleAdd() {
      this.title = "新增分组";
      this.open = true;
      this.form = { parentId: [Number(this.allId)], remark: "", name: "" };
    },
    // 修改
    handleUpdate(row) {
      this.title = "修改分组";
      this.form = {
        id: row.id,
        parentId:
          row.parentId == this.allId
            ? [row.parentId]
            : [Number(this.allId), row.parentId],
        remark: row.remark,
        name: row.label,
      };
      this.open = true;
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.parentId = this.form.parentId.pop();
          if (this.form.parentId == this.allId) {
            this.form.level = 2;
          } else {
            this.form.level = 3;
          }
          if (this.form.id != undefined) {
            updateGroup(this.form).then((response) => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addGroup(this.form).then((response) => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding-right: 8px;
}
.custom-tree-node.active {
  color: #1890ff;
}
.el-tree-node__content {
  height: 40px !important;
}
</style>