
import store from '@/store';
import { wsUrl } from "../../utils/setting";
console.log("this.$store", store);
const HEARTBEAT_INTERVAL = 60000; // 心跳间隔（5 秒）
let socket = null;

const websocket = {
    state: { //状态 data 单一数据源
        socket: null, //建立的连接
        isConnected: false, //是否真正建立连接
        heartbeatInterval: 60000, //15秒一次心跳
        heartbeatTimer: null,
        timeoutObj: null, //心跳心跳倒计时
        serverTimeoutObj: null, //心跳倒计时
        timeoutnum: null, //断开 重连倒计时
        msg: null,//接收到的信息
        heartbeatIntervalId: null
    },
    mutations: { //用于更改state  同步操作 使用commit来触发
        SET_SOCKET(state, socket) {
            state.socket = socket;
        },
        SET_CONNECTED(state, isConnected) {
            state.isConnected = isConnected;
        },
        START_HEARTBEAT(state) {
            return
            state.heartbeatIntervalId = setInterval(() => {
                if (state.socket && state.socket.readyState === WebSocket.OPEN) {
                    console.log("正在执行心跳检测")
                    state.socket.send('ping');
                }
            }, HEARTBEAT_INTERVAL);
        },
        STOP_HEARTBEAT(state) {
            clearInterval(state.heartbeatIntervalId);
            state.heartbeatIntervalId = null;
        },
        SET_MESSAGE: (state, msg) => {
            state.msg = msg;
        },
    },
    actions: { //用于更改state 异步操作或者复杂操作 通过提交muations来更改state  使用dispatch来触发   可以来触发muations

        async webSocketConnect({ commit, rootState }) {
            try {
                let WSSURL = wsUrl + rootState.user.user_id;
                if (!socket) {
                    socket = new WebSocket(WSSURL);
                }
                commit('SET_SOCKET', socket);
                socket.onopen = () => {
                    console.log("socket已开启 ==========");
                    commit('SET_CONNECTED', true);
                    commit('START_HEARTBEAT');
                };
                socket.onmessage = (event) => {
                    const message = event.data;
                    if (message === 'pong') {

                        // 处理心跳响应
                        console.log("收到服务器心跳检测");
                    } else {
                        commit('SET_MESSAGE', JSON.parse(message));
                    }

                };
                socket.onerror = (error) => {
                    console.log(error)
                    // throw error;
                };
                socket.onclose = () => {
                    commit('SET_CONNECTED', false);
                    commit('STOP_HEARTBEAT');
                };
            } catch (error) {
                console.log('WebSocket connection error:', error);
            }
        },

        webSocketDisconnect({ commit }) {
            if (this.state.socket) {
                this.state.socket.close();
                commit('SET_SOCKET', null);
                commit('SET_CONNECTED', false);
                commit('STOP_HEARTBEAT');
            }
        }

    }
};

export default websocket;

// - 用户登录后，connect websocket
// - 用户登出后，close websocket链接，否则会有内存溢出
// 测试 wss://crm.1qiyu.com/api/websocket/{{userId}}
// 生产 wss://crm.iqiyu.net/api/websocket/{{userId}}

