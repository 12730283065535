import request from '@/utils/request'

//查询数据列表
export function listData(query) {
  return request({
    url: '/business/CallOriginalData/list',
    method: 'get',
    params: query
  })
}

// 删除数据
export function delData(ids) {
  return request({
    url: '/business/CallOriginalData/'+ids,
    method: 'delete'
  })
}


// 修改数据分组
export function editingGroup(data) {
  return request({
    url: '/business/CallOriginalData/editingGroup',
    method: 'post',
    data
  })
}


// 导出数据
export function exportData(ids) {
    return request({
      url: '/business/CallOriginalData/export',
      method: 'get'
    })
  }
  
  // 下载模板
  export function downLoadData() {
    return request({
      url: '/business/CallOriginalData/importTemplate',
      method: 'get'
    })
  }

  

//查询数据操作列表
export function listRecordTable(query) {
  return request({
    url: '/business/CallOperationRecords/list',
    method: 'get',
    params: query
  })
}

//新增数据操作
export function addRecordTable(data) {
  return request({
    url: '/business/CallOperationRecords/save',
    method: 'post',
   data
  })
}

//修改数据操作
export function updateRecordTable(query) {
  return request({
    url: '/business/CallOperationRecords/edit',
    method: 'post',
    data: query
  })
}


//删除数据操作
export function  delRecordTable(query) {
  return request({
    url: '/business/CallOperationRecords/' + query,
    method: 'delete'
  })
}