/*
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-09-19 17:11:29
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-10-29 21:22:38
 * @FilePath: /call_manage_system_vue/src/permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'
import { homeUrl, baseURL } from "./utils/setting";


NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/auth-redirect', '/bind', '/register']
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    if (window.aegis) {
        window.aegis.infoAll({
            msg: 'pc-监听页面从哪到哪,' + window.localStorage.getItem('aegisUin'),
            ext1: '来自哪个页面' + from.path,
            ext2: '去哪个页面' + to.path,
            trace: 'trace',
        });
    }
    if (getToken()) {
        /* has token*/
        if (to.path === '/register') {
            // if (window.aegis) {
            //     window.aegis.infoAll({
            //         msg: 'pc-跳转注册页强制退出需要重新登录' + window.localStorage.getItem('aegisUin'),
            //         ext1: '来自哪个页面' + from.path,
            //         ext2: '去哪个页面' + to.path,
            //         trace: 'trace',
            //     });
            // }
            // store.dispatch('webSocketDisconnect');//关闭websocket
            // removeToken()
            // store.dispatch("LogOut").then(() => {
            //     console.log('LogOut')
            //     // location.href = "/index";
            //     if (window.aegis) {
            //         window.aegis.infoAll({
            //             msg: 'pc-直接访问register导致退出',
            //             trace: 'trace',
            //         });
            //     }
            //     next({ path: '/register' })//{ path: '/' }
            // });
            // return
        }
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        } else {
            if (store.getters.roles.length === 0) {
                // 判断当前用户是否已拉取完user_info信息
                let res;
                try {
                    res = await store.dispatch('GetInfo')
                } catch {
                    res = { code: 1 }
                }
                if (res.code == 200) {
                    try {
                        let accessRoutes = await store.dispatch('GenerateRoutes')
                        // 根据roles权限生成可访问的路由表
                        router.addRoutes(accessRoutes) // 动态添加可访问路由表
                        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成s
                    } catch {
                        next()
                    }
                } else {
                    if (window.aegis) {
                        window.aegis.infoAll({
                            msg: 'pc-获取不到用户信息权限退出需要重新登录' + window.localStorage.getItem('aegisUin'),
                            ext1: 'ext',
                            trace: 'trace',
                        });
                    }
                    store.dispatch('webSocketDisconnect');//关闭websocket
                    removeToken()
                    store.dispatch("LogOut").then(() => {
                        console.log('LogOut')
                        if (window.aegis) {
                            window.aegis.infoAll({
                                msg: 'pc-获取不到用户信息跳转到登录页' + window.localStorage.getItem('aegisUin'),
                                trace: 'trace',
                            });
                        }
                        // location.href = "/index";
                        next({ path: '/register' })//{ path: '/' }
                    });
                }
            } else {
                next()
            }
        }
    } else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            //   window.location.href = homeUrl+"?event=relogin";
            // return;
            if (window.aegis) {
                window.aegis.infoAll({
                    msg: 'pc-获取不到token跳转到登录页' + window.localStorage.getItem('aegisUin'),
                    trace: 'trace',
                });
            }
            next({ path: '/register' })
            // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            // NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
