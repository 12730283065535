<template>
  <div>
    <el-dialog
      title="记录表"
      :visible.sync="visible"
      width="1200px"
      top="5vh"
      append-to-body
    >
      <!-- <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="表名称" prop="tableName">
        <el-input
          v-model="queryParams.tableName"
          placeholder="请输入表名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表描述" prop="tableComment">
        <el-input
          v-model="queryParams.tableComment"
          placeholder="请输入表描述"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form> -->
      <el-row>
        <el-table
          @row-click="clickRow"
          ref="table"
          :data="dbTableList"
          @selection-change="handleSelectionChange"
          height="260px"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column prop="id" width="55"></el-table-column>
          <el-table-column prop="contactName" label="联系人"></el-table-column>
          <el-table-column
            prop="contactType"
            label="联系方式"
          ></el-table-column>
          <el-table-column
            prop="contactNote"
            label="联系小记"
          ></el-table-column>
          <el-table-column prop="remark" label="标签"></el-table-column>
          <el-table-column label="联系时间" align="center" prop="contactTime">
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.contactTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="open"
      width="1000px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="form.contactName" placeholder="请输入联系人" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式" prop="contactType">
              <el-input
                v-model="form.contactType"
                placeholder="请输入联系方式"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系时间" prop="contactTime">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.contactTime"
                type="date"
                placeholder="选择时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="标签" prop="remark">
              <el-input v-model="form.remark" placeholder="请输入标签" />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="联系小记" prop="contactNote">
              <el-input
                v-model="form.contactNote"
                type="textarea"
                placeholder="请输入小记"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listRecordTable,
  delRecordTable,
  updateRecordTable,
  addRecordTable,
} from "@/api/custom/origin";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 遮罩层
      visible: false,
      // 选中数组值
      tables: [],
      // 总条数
      total: 0,
      // 表数据
      dbTableList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      // 弹框
      rules: {
        contactName: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "联系标签不能为空", trigger: "blur" },
        ],
        //  contactNote: [
        //   { required: true, message: "联系小记不能为空", trigger: "blur" },
        // ],
        contactType: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],

        contactTime: [
          { required: true, message: "请选择联系时间", trigger: "change" },
        ],
      },
      form: {
        contactName: "",
        remark: "",
        contactTime: "",
        contactNote: "",
        contactType: "",
      },
      open: false,
      title: "",
    };
  },
  methods: {
    // 显示弹框
    show() {
      this.getList();
      this.visible = true;
    },
    clickRow(row) {
      this.$refs.table.toggleRowSelection(row);
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.tables = selection.map((item) => item.tableName);
    },
    // 查询表数据
    getList() {
      listRecordTable({ ...this.queryParams, resourceDataId: this.id }).then(
        (res) => {
          if (res.code === 200) {
            this.dbTableList = res.rows;
            this.total = res.total;
          }
        }
      );
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const tableIds = row.id;

      this.$confirm('是否确认删除id为"' + tableIds + '"的操作数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function() {
          return delRecordTable(tableIds);
        })
        .then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        });
    },
    // 添加
    handleAdd() {
      this.open = true;
      this.title = "新增操作记录";
      this.form = {};
    },
    // 修改
    handleUpdate(row) {
      this.open = true;
      this.title = "修改操作记录";
      this.form = {
        id: row.id,
        contactName: row.contactName,
        remark: row.remark,
        contactTime: row.contactTime,
        contactNote: row.contactNote,
        contactType: row.contactType,
      };
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateRecordTable(this.form).then((response) => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addRecordTable(this.form).then((response) => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
  },
};
</script>
