/*
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-08-05 18:11:11
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-08-11 09:44:10
 * @FilePath: /qiyu-official-website-vue/src/request/axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import router from '@/router/index'
import { axiosBaseURL } from "@/utils/setting";

// import { ElNotification, ElMessageBox, ElMessage } from 'element-plus'
import { Message } from 'element-ui';
import { removeToken, getToken, getGroup } from "@/utils/auth";
const service = axios.create({
    timeout: 30000,//数据响应过期时间
    baseURL: axiosBaseURL
})
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//与后端进行数据交换的时候带上cookie
axios.defaults.withCredentials = true;
//请求拦截器
service.interceptors.request.use((config) => {
    //是否需要设置 token
    const isToken = config.headers["isToken"] === false;
    console.log('getToken', getGroup())
    if (getToken() && !isToken) {
        config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, (error) => {
    //对请求错误做点什么
    return error
})

//响应拦截器
service.interceptors.response.use((res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg;
    if (code === 401) {
        removeToken()
        // ElMessage({
        //     message: '登录状态已过期，请重新登录',
        //     type: 'warning',
        // })
        if (window.aegis) {
            window.aegis.infoAll({
                msg: 'pc-401跳转到登录页' + window.localStorage.getItem('aegisUin'),
                trace: 'trace',
            });
        }
        router.push("/register")
    } else if (code === 500) {
        Message({
            message: msg,
            type: "error",
        });
        return;
    } else if (code !== 200) {
        Message({
            message: msg,
            type: "error",
        });
        // ElNotification.error({
        //     title: msg,
        // });
        return;
    } else {
        return res.data;
    }
})
//导出
export default service