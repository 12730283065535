<!--
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-08-05 18:11:11
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-08-13 19:29:44
 * @FilePath: /qiyu-official-website-vue/src/components/CusButton.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div :class="['cus-button', btnTypeStyle]" @click="handleClick">
        <span v-if="!isLoading">{{ text }}</span>
        <div class="b-button-loading" v-else>
            <svg viewBox="0 0 50 50" class="loading-svg b-button-size-huge b-button-primary">
                <circle cx="25" cy="25" r="20" fill="none" class="path" style="stroke: rgba(255, 255, 255, 0.6);">
                </circle>
            </svg>
        </div>
    </div>
</template>

<script>
// import { defineProps, computed, defineEmits} from "vue"
import { computed } from "vue"
export default {
    data() {
        return {
            showEye: false,
        }
    },
    props: {
        text: {
            type: String,
            default: "免费试用"
        },
        type: {
            type: String,
            default: "cur-default-btn"
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        btnTypeStyle() {
            if (this.type === "large-button") {
                return "cus-large-button"
            } else {
                return "cur-default-btn"
            }
        }
    },
    methods: {
        handleClick() {
            console.log("点击按钮");
            this.$emit("click")
            console.log('结果是');
        }
    }
}
// const emit = defineEmits(['handleBtn'])
// const props = defineProps({
//     text: {
//         type: String,
//         default: "免费试用"
//     },
//     type: {
//         type: String,
//         default: "cur-default-btn"
//     },
//     isLoading: {
//         type: Boolean,
//         default: false
//     }
// })
// const handleClick = () => {
//     console.log("点击按钮", emit);
//     let ress = emit("handleBtn")
//     console.log('结果是', ress);
// }
// let btnTypeStyle = computed(() => {
//     if (props.type === "large-button") {
//         return "cus-large-button"
//     } else {
//         return "cur-default-btn"
//     }
// })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$themeColor: #2171FE;
$hoverThemColor: #409eff;

.cus-large-button {
    display: block;
    height: 55px;
    line-height: 55px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 500;
}

.cur-default-btn {
    display: inline-block;
    border-radius: 20px;
    padding: 9px 24px;
}

.cus-button {
    position: relative;
    background-color: $themeColor;
    color: #fff;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        background-color: $hoverThemColor;
    }
}

.b-button-loading {
    cursor: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-svg {
        width: 34px;
        height: 34px;
        animation: loading-rotate 1.5s ease-in-out infinite;
    }

    .path {
        -webkit-animation: loading-dash 1.5s ease-in-out infinite;
        animation: loading-dash 1.5s ease-in-out infinite;
        stroke-dasharray: 90, 150;
        stroke-dashoffset: 0;
        stroke-width: 5;
        stroke: #409EFF;
        stroke-linecap: round;
    }
}
</style>
