<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { getToken, removeToken } from "@/utils/auth";
export default {
    name: "App",
    beforeUpdate() {
        console.log(getToken());
        if (getToken()) {
            this.$store.commit("SET_TOKEN", getToken());
        } else {
            this.$store.commit("SET_TOKEN", "");
            this.$store.commit("SET_ROLES", []);
            this.$store.commit("SET_PERMISSIONS", []);
            removeToken();
        }
        this.$store.dispatch("webSocketConnect"); //初始化ws
    },
    beforeDestroy() {
        this.$store.dispatch("webSocketDisconnect"); //关闭websocket
    },
};
</script>
<style lang="scss">
.crm-selectTre {
    span {
        width: 100%;
        text-align: center;
        height: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span:hover {
        color: #ffffff;
        background-color: #1890ff;
    }
}

.el-select-dropdown__list .el-select-dropdown__item.hover {
    background: #46a6ff !important;
    color: #fff !important;
}

::v-deep .el-radio input[aria-hidden="true"] {
  display: none !important;
}

::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}
</style>
